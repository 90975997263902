<template>
    <div style="padding: 0 12px;box-sizing: border-box">
        <adContent></adContent>
        <div class="game-cont">
            <div v-for="(item,i) in gameList" :key="i">
                <div class="style1">
                    <img
                        :class="{
                            'pointer': true,
                            'style-1-img': item.type === 1,
                            'style-2-img': item.type === 2,
                        }"
                        v-for="items in item.games"
                        :key="items.id"
                        :src="items.logo"
                        @click="goDetails(items)"
                        alt=""
                    >
                </div>
            </div>
            <span></span>
        </div>
        <div class="game-blurb noselect">
            Welcome to {{blurbUrl}} Here, various free online games can be played directly in your browser without any downloads. Our collection of online games will provide you with hours ofentertainment. Invite your friends over and enjoy these popular games together. From classic arcade games to car games, cricket games, puzzles, mahjong, and more, there's something for everyone in our game categories. We have carefully selected the best online games, and we review and add new games every day, so you can learn new skills as you play! Let's start playing
        </div>
    </div>
</template>

<script>
import {
    getGame
} from "@/api/games";
import adContent from "@/components/adContent/index.vue";
export default {
        components: {
            adContent
        },
        data() {
            return {
                gameList:[],
                blurbUrl: process.env.VUE_APP_BASE_GAME,
                allList: []
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            getGame(){
                if(this.allList.length<23){
                    return
                }else{
                    let games = this.allList.splice(0,10);
                    this.gameList.push({
                        type: 1,
                        games
                    })
                    games = this.allList.splice(0,13);
                    this.gameList.push({
                        type: 2,
                        games
                    })
                    this.getGame();
                }
            },
            async getList(){
                this.allList = await getGame();
                this.getGame();
            }
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.game-cont{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .style1{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 9px;
        margin: 9px 13px;
        img:nth-child(5){
            width: 261px;
            height: 261px;
            grid-column: 1 / 4;
            grid-row: 2 / 5;
        }
        .style-1-img:nth-child(9){
            width: 171px;
            height: 171px;
            grid-column: 1 / 3;
        }
        .style-1-img:nth-child(10){
            width: 171px;
            height: 171px;
            grid-column: 3 / 5;
        }
        .style-2-img:nth-child(9){
            width: 171px;
            height: 171px;
            grid-column: 1 / 3;
            grid-row: 5 / 7;
        }
        img{
            width: 82px;
            height: 82px;
            border-radius: 11px;
        }
    }
    span{
        width: 381px;
    }
}
.game-blurb{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>