<template>
    <div>
        <div class="header">
            <img class="logo pointer" src="@/assets/slices/logo.png" alt="" @click="goIndex">
            <div class="search">
                <input v-model="searchText" @keydown.enter="searchGame" type="text" placeholder="keywords">
                <van-icon class="pointer" name="search" color="#24BAFF" size="17" @click="searchGame" />
            </div>
            <img @click="goSort" class="class pointer" src="@/assets/slices/class.png" alt="">
        </div>
    </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
    export default {
        data() {
            return {
                showMenu: false,
                searchText: ''
            }
        },
        mounted() {

        },
        methods: {
            goIndex(){
                if(this.$route.path === '/'){
                    this.$router.go(0)
                }else{
                    this.$router.push('/')
                }
            },
            searchGame(){
                if(this.$route.path !== '/selectGame'){
                    this.$router.push({
                        path: '/selectGame',
                        query: {
                            id: 'search',
                            key: this.searchText
                        }
                    })
                }else{
                    eventBus.$emit('searchgame',{
                        id: 'search',
                        key: this.searchText
                    })
                }
            },
            goSort(){
                if(this.$route.path !== '/sort'){
                    this.$router.push('/sort')
                }else{
                    this.$router.go(0)
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .header{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .header{
    width: 100%;
  }
}
.pointer{
    cursor: pointer;
}
.header{
    height: 50px;
    background: #24BAFF;
    position: fixed;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    .logo{
        width: 69px;
        height: 23px;
    }
    .search{
        width: 231px;
        height: 38px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        input{
            &::placeholder{
                color: #AAADBE;
            }
            width: 90%;
            height: 22px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #24BAFF;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            border: none;
            outline: none;
        }
    }
    .class{
        width: 36px;
        height: 36px;
    }
}
</style>