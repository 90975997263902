<template>
    <div style="padding: 0 15px;box-sizing: border-box">
        <div class="sort">
            <div
                class="sort-card pointer"
                v-for="item in classList"
                :key="item.key_name"
                @click="goSelect(item.key_name)"
            >
                <img :src="item.url" alt="">
                <div>{{item.key_name}}</div>
            </div>
            <span v-for="i in 5" :key="i"></span>
        </div>
    </div>
</template>

<script>
import {
    getClassList,
    getGame
} from "@/api/games";

export default {
    data (){
        return {
            classList: []
        }
    },
    mounted() {
        this.getClassList();
    },
    methods:{
        async getClassList(){
            let list = await getClassList();
            let picList = await getGame({count:list.length});
            list.forEach((item,i)=>{
                item.url = picList[i].logo;
            })
            this.classList = list;
        },
        goSelect(key){
            this.$router.push({
                path: '/selectGame',
                query: {
                    id: 'more',
                    key
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sort{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .sort-card{
        margin-bottom: 8px;
        width: 161px;
        height: 50px;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-items: center;
        img{
            width: 50px;
            height: 50px;
            border-radius: 10px;
            margin-right: 8px;
        }
        div{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #5F5F5F;
            line-height: 50px;
            text-align: left;
            font-style: normal;
        }
    }
    span{
        width: 161px;
    }
}
</style>